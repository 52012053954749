<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : null">
    <!--begin::Card-->
    <div class="card card-custom card-transparent">
      <OverlayLoader :is-submitting="loader.show" :message="loader.message" :timing="loader.timing">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div id="kt_wizard_v4" class="wizard wizard-4" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-icon">
                      <i class="wizard-check ki ki-check" />
                      <span class="wizard-number">1</span>
                    </div>
                    <div class="wizard-label">
                      <h6 class="wizard-title">Identificação</h6>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-icon">
                      <i class="wizard-check ki ki-check" />
                      <span class="wizard-number">2</span>
                    </div>
                    <div class="wizard-label">
                      <h6 class="wizard-title">Fotos</h6>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-icon">
                      <i class="wizard-check ki ki-check" />
                      <span class="wizard-number">3</span>
                    </div>
                    <div class="wizard-label">
                      <h6 class="wizard-title">Endereço</h6>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-icon">
                      <i class="wizard-check ki ki-check" />
                      <span class="wizard-number">4</span>
                    </div>
                    <div class="wizard-label">
                      <h6 class="wizard-title">Conclusão</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body">
                <div class="row justify-content-center px-8 px-lg-10">
                  <div class="col-xl-12 col-xxl-12 p-0">
                    <!--begin: Wizard Form-->
                    <b-form class="form mt-0 mt-lg-10">
                      <div class="row">
                        <div class="col-lg-8">
                          <!--begin: Wizard Step 1-->
                          <div class="" data-wizard-type="step-content" data-wizard-state="current">
                            <h1 class="display-4 mb-10">Complete seu Perfil</h1>
                            <b-card bg-variant="light" border-variant="primary">
                              <!--input name-->
                              <b-form-group label="Nome Completo">
                                <b-form-input
                                  v-model="$v.form.step_1.name.$model"
                                  class="form-control"
                                  :state="validate('step_1', 'name')"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationMsg($v.form.step_1.name) }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <div class="row">
                                <!--input cpf/cnpj-->
                                <div class="col-lg-4">
                                  <b-form-group label="CPF ou CPNJ">
                                    <b-form-input
                                      v-model="$v.form.step_1.code.$model"
                                      v-mask="
                                        form.step_1.type.options.find(
                                          (option) => option.value == $v.form.step_1.type.selected.$model
                                        ).mask
                                      "
                                      :state="validate('step_1', 'code')"
                                      class="form-control"
                                    />
                                    <b-form-invalid-feedback>
                                      {{ validationMsg($v.form.step_1.code) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                                <!--input type document-->
                                <div class="col-lg-8">
                                  <b-form-group label="Tipo Pessoa">
                                    <b-form-radio-group
                                      v-model="$v.form.step_1.type.selected.$model"
                                      :options="form.step_1.type.options"
                                      buttons
                                      button-variant="outline-primary"
                                      size="xs"
                                      :state="validate('step_1', 'type')"
                                    ></b-form-radio-group>
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="row">
                                <!--input birth date-->
                                <div class="col-lg-4">
                                  <b-form-group
                                    :label="form.step_1.type.selected == 1 ? 'Data Nascimento' : 'Data de Abertura'"
                                  >
                                    <b-form-input
                                      v-model="$v.form.step_1.date_birth.$model"
                                      v-mask="['##/##/####']"
                                      :state="validate('step_1', 'date_birth')"
                                    />
                                    <b-form-invalid-feedback>
                                      {{ validationMsg($v.form.step_1.date_birth) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                                <!--input genre-->
                                <div class="col-lg-8">
                                  <b-form-group label="Sexo">
                                    <b-form-radio-group
                                      v-model="$v.form.step_1.genre.selected.$model"
                                      :options="form.step_1.genre.options"
                                      buttons
                                      button-variant="outline-primary"
                                      size="xs"
                                      :state="validateRadio('step_1', 'genre')"
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                      :style="$v.form.step_1.genre.selected.$invalid ? 'display:block' : ''"
                                    >
                                      {{ validationMsg($v.form.step_1.genre.selected) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="row">
                                <!--input rg-->
                                <div class="col-lg-4">
                                  <b-form-group label="Nº do documento">
                                    <b-form-input
                                      v-model="$v.form.step_1.registration_document.$model"
                                      placeholder="RG,CNH"
                                      :disabled="$v.form.step_1.type.selected.$model == 2"
                                      :state="validate('step_1', 'registration_document')"
                                    />
                                    <b-form-invalid-feedback>
                                      {{ validationMsg($v.form.step_1.registration_document) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </div>

                                <!--input rg expeditor-->
                                <div class="col-lg-8">
                                  <b-form-group label="Orgão expedidor">
                                    <b-form-input
                                      v-model="$v.form.step_1.responsible_document.$model"
                                      :disabled="$v.form.step_1.type.selected.$model == 2"
                                      :state="validate('step_1', 'responsible_document')"
                                    />
                                    <b-form-invalid-feedback>
                                      {{ validationMsg($v.form.step_1.responsible_document) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                              </div>
                            </b-card>
                          </div>

                          <!--begin: Wizard Step 2-->
                          <div data-wizard-type="step-content">
                            <h1 class="display-4 mb-10">Nos forneça algumas fotos</h1>
                            <b-card bg-variant="light" border-variant="primary">
                              <p>Nós envie uma foto do seu documento de Identidade, sendo ele CNH ou RG.</p>

                              <b-form-group label="Frente:">
                                <b-form-file
                                  id="file-documentFront"
                                  ref="file"
                                  v-model="$v.form.step_2.fileDocumentFront.$model"
                                  placeholder="Selecione ou solte o arquivo aqui..."
                                  browse-text="Buscar"
                                  :state="validate('step_2', 'fileDocumentFront')"
                                  accept=".jpg, .png, .gif"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationMsg($v.form.step_2.fileDocumentFront) }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group label="Verso:">
                                <b-form-file
                                  id="file-documentVerse"
                                  v-model="$v.form.step_2.fileDocumentVerse.$model"
                                  placeholder="Selecione ou solte o arquivo aqui..."
                                  browse-text="Buscar"
                                  :state="validate('step_2', 'fileDocumentVerse')"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationMsg($v.form.step_2.fileDocumentVerse) }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <div class="mb-10 text-dark">
                                Forneça uma foto (selfie) segurando sua
                                <b>identidade</b>
                                junto com uma folha de papel escrito
                                <b>SnailBank</b>
                                e a
                                <b>data de hoje</b>
                                !
                              </div>

                              <b-form-group label="Foto (Snailbank + Data de hoje):">
                                <b-form-file
                                  id="file-documentSelfie"
                                  v-model="$v.form.step_2.fileDocumentSelfie.$model"
                                  placeholder="Selecione ou solte o arquivo aqui..."
                                  browse-text="Buscar"
                                  :state="validate('step_2', 'fileDocumentSelfie')"
                                />
                                <b-form-invalid-feedback>O upload do documento é necessario.</b-form-invalid-feedback>
                              </b-form-group>

                              <div class="row align-items-center mt-5">
                                <b-col md="6">
                                  <b-card-img src="/media/misc/verification_doc.png" class="rounded-0 w-60" />
                                </b-col>
                                <b-col md="6">
                                  <ul class="list-unstyled love">
                                    <li>
                                      <b-icon-check-circle />
                                      Titular da conta
                                    </li>
                                    <li>
                                      <b-icon-check-circle />
                                      Identidade
                                    </li>
                                    <li>
                                      <b-icon-check-circle />
                                      Folha escrito "Snailbank"
                                    </li>
                                    <li>
                                      <b-icon-check-circle />
                                      Folha escrito a data de hoje
                                    </li>
                                  </ul>
                                </b-col>
                              </div>
                            </b-card>
                          </div>

                          <!--begin: Wizard Step 3-->
                          <div data-wizard-type="step-content">
                            <h1 class="display-4 mb-10">Forneça o seu endereço</h1>
                            <b-card bg-variant="light" border-variant="primary">
                              <b-alert :variant="gettingCep.status" :show="gettingCep.show" dismissible>
                                {{ gettingCep.message }}
                              </b-alert>
                              <b-form-group label="Digite o cep">
                                <b-form-input
                                  v-model="$v.form.step_3.zip_code.$model"
                                  v-mask="'#####-###'"
                                  class="form-control"
                                  :state="validate('step_3', 'zip_code')"
                                  @keyup.native="searchCep"
                                />
                                <b-form-invalid-feedback>Digite seu cep!</b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group label="Endereço:" label-for="address">
                                <b-form-input
                                  ref="address"
                                  v-model="$v.form.step_3.address.$model"
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  :state="validate('step_3', 'address')"
                                />
                                <b-form-invalid-feedback>O campo endereço é obrigatório.</b-form-invalid-feedback>
                              </b-form-group>

                              <div class="row">
                                <div class="col-8">
                                  <b-form-group label="Bairro:" label-for="neighborhood">
                                    <b-form-input
                                      ref="neighborhood"
                                      v-model="$v.form.step_3.neighborhood.$model"
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      :state="validate('step_3', 'neighborhood')"
                                    />
                                    <b-form-invalid-feedback>O campo bairro é obrigatório.</b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                                <div class="col-4">
                                  <b-form-group label="Numéro:" label-for="number">
                                    <b-form-input
                                      ref="number"
                                      v-model="$v.form.step_3.number.$model"
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      :state="validate('step_3', 'number')"
                                    />
                                    <b-form-invalid-feedback>O campo número é obrigatório.</b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-6">
                                  <b-form-group label="Estado:" label-for="state">
                                    <b-form-select
                                      ref="state"
                                      v-model="$v.form.step_3.ecosystem_state_id.$model"
                                      :options="optionsState"
                                      value-field="state_id"
                                      text-field="state_name"
                                      class="form-control"
                                      :state="validate('step_3', 'ecosystem_state_id')"
                                    >
                                      <template #first>
                                        <b-form-select-option :value="null" disabled>
                                          -- Please select an option --
                                        </b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </div>
                                <div class="col-6">
                                  <b-form-group label="Cidade:" label-for="city">
                                    <b-form-select
                                      ref="cities"
                                      v-model="$v.form.step_3.ecosystem_city_id.$model"
                                      :options="optionsCities"
                                      value-field="id"
                                      text-field="name"
                                      class="form-control"
                                      :state="validate('step_3', 'ecosystem_city_id')"
                                    >
                                      <template #first>
                                        <b-form-select-option :value="null" disabled>
                                          {{
                                            $v.form.step_3.ecosystem_state_id.$model
                                              ? '-- Selecione a Cidade --'
                                              : '-- Selecione o Estado --'
                                          }}
                                        </b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </div>
                              </div>

                              <b-form-group label="Comprovante de endereço:">
                                <b-form-file
                                  id="file-fileDocumentAddress"
                                  v-model="$v.form.step_3.fileDocumentAddress.$model"
                                  browse-text="Buscar"
                                  placeholder="Selecione ou solte o arquivo aqui..."
                                  :state="validate('step_3', 'fileDocumentAddress')"
                                  accept=".jpg, .png, .gif"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationMsg($v.form.step_3.fileDocumentAddress) }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-card>
                          </div>

                          <!--begin: Wizard Step 3-->
                          <div class="pb-5 pt-10" data-wizard-type="step-content">
                            <b-card bg-variant="light" class="p-0">
                              <div class="tos" @scroll.passive="handleScroll">
                                <p>
                                  Bacon ipsum dolor sit amet short loin andouille pork, ground round t-bone prosciutto
                                  brisket chuck pig ball tip ham bacon. Biltong leberkas venison, flank kielbasa filet
                                  mignon kevin swine short loin frankfurter t-bone. Ball tip meatloaf short ribs ham
                                  brisket pork chop ham hock tenderloin jowl flank t-bone pork loin jerky short loin
                                  strip steak. Andouille meatball filet mignon frankfurter chuck turducken pork loin
                                  jowl pig. Sirloin spare ribs short loin pastrami tri-tip fatback strip steak bacon cow
                                  ribeye pork t-bone turducken pork chop pork loin.
                                </p>
                                <p>
                                  Ground round hamburger pig short loin rump cow. Turducken tri-tip ribeye chicken
                                  andouille pork belly rump strip steak corned beef capicola sausage. Andouille sirloin
                                  hamburger tail t-bone. Sirloin chicken sausage bacon shankle tenderloin brisket doner
                                  flank pastrami rump short loin shank jowl. Capicola ham shank, tenderloin kielbasa
                                  ground round bresaola turducken shoulder drumstick boudin. Pancetta tri-tip turducken
                                  frankfurter beef ball tip. Venison leberkas turkey salami ham hock, pork loin tri-tip
                                  jerky tenderloin biltong pastrami spare ribs doner drumstick pancetta.
                                </p>
                                <p>
                                  Beef ribs ball tip venison meatloaf, kielbasa ham fatback ham hock cow boudin t-bone
                                  ribeye. Strip steak pork chop filet mignon, corned beef beef ribs bacon salami pig
                                  ball tip turkey jerky capicola prosciutto. Pork drumstick jerky short ribs bresaola
                                  tongue pork chop turkey sausage shankle ribeye salami spare ribs meatball ball tip.
                                  Boudin tail hamburger sausage ground round tongue.
                                </p>
                                <p>
                                  Spare ribs jowl turkey, shoulder bresaola swine andouille boudin ham turducken sirloin
                                  ball tip capicola filet mignon leberkas. Pork pork loin bresaola filet mignon shank
                                  pig. Chuck kielbasa ball tip shankle, andouille bresaola meatloaf flank short ribs.
                                  Biltong meatloaf chuck flank beef pastrami ham hock strip steak boudin kevin leberkas
                                  hamburger ground round salami. Shankle sirloin pork loin drumstick turducken ball tip
                                  venison jerky meatloaf salami boudin. Turkey tri-tip pork belly fatback spare ribs
                                  shank filet mignon t-bone flank ham kielbasa drumstick tenderloin. Andouille shankle
                                  ribeye sirloin, filet mignon beef ribs turducken pork loin salami.
                                </p>
                                <p>
                                  Hamburger pork loin brisket beef pig bacon tenderloin strip steak short loin shank
                                  ribeye bresaola beef ribs pastrami. Turkey chuck beef drumstick swine. Pastrami
                                  tri-tip andouille, pork loin t-bone hamburger shoulder pork chop kevin. Prosciutto
                                  pork chop doner, rump bacon filet mignon leberkas bresaola kielbasa jerky boudin
                                  tri-tip pastrami tail. Sausage salami leberkas, jowl spare ribs pastrami frankfurter
                                  ball tip doner t-bone boudin pork loin andouille corned beef jerky. Turducken pig
                                  chicken, meatloaf drumstick boudin kevin. Pig chicken ham swine fatback.
                                </p>
                              </div>
                            </b-card>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <b-card header-tag="header">
                            <template v-slot:header>
                              <h6 class="mb-0">Agilizando sua aprovação</h6>
                            </template>
                            <b-card-text>
                              <ul class="list-unstyled love">
                                <li>
                                  <b-icon-check-circle />
                                  Todos os documentos enviados devem pertencer ao titular da conta.
                                </li>
                                <li>
                                  <b-icon-check-circle />
                                  Ultilize apenas imagens no formato: PNG ,JPG ou PDF.
                                </li>
                                <li>
                                  <b-icon-check-circle />
                                  Cada arquivo deve conter no máximo 5 megabytes.
                                </li>
                                <li>
                                  <b-icon-check-circle />
                                  Ultileze ambientes claros e com boa iluminação para tirar sua foto.
                                </li>
                                <li>
                                  <b-icon-check-circle />
                                  Tente tirar a foto o mais frontal possível.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card>
                        </div>
                      </div>

                      <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10">
                        <div class="mr-2">
                          <button
                            class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-prev"
                          >
                            Voltar
                          </button>
                        </div>
                        <div>
                          <button
                            ref="btn_submit"
                            :disabled="!terms"
                            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-submit"
                            @click="onSubmit"
                          >
                            Enviar Documentos
                          </button>
                          <button
                            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-next"
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </b-form>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Bpdy-->
          </div>
          <!--end: Wizard-->
        </div>
      </OverlayLoader>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
.wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
  flex: 0 0 calc(25% - 0.25rem) !important;
}
.list-unstyled.love li {
  padding-bottom: 10px;
}
.tos {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}
</style>

<script>
import KTWizard from '@/assets/js/components/wizard';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMessage } from 'vuelidate-messages';
import formMessages from '@/core/utils/validationMessages';
import { mapGetters } from 'vuex';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import addressMixin from '@/core/mixins/addressMixin';
import cep from 'cep-promise';

const validateTypeDocument = (file) => {
  var allowedExtensions = ['image/jpeg', 'image/png', 'image/gif'];
  if (!file) {
    return true;
  }
  if (allowedExtensions.includes(file.type)) {
    return true;
  }
  return false;
};

const validateFileSize = (file) => {
  var maxSize = 8; //8mb
  if (!file) {
    return true;
  }
  if (file.size < maxSize * 1024 * 1024) {
    return true;
  }
  return false;
};

export default {
  components: { OverlayLoader },
  mixins: [validationMixin, addressMixin],
  data() {
    return {
      loader: {
        show: false,
        message: '',
        timing: false
      },
      terms: false,
      gettingCep: {
        show: false,
        status: '',
        message: ''
      },
      form: {
        step_1: {
          name: null,
          type: {
            selected: this.$store.getters.currentCustomer.type,
            options: [
              { text: 'Pessoa Fisíca', value: '1', mask: '###.###.###-##' },
              { text: 'Pessoa Jurídica', value: '2', mask: '##.###.###/####-##' }
            ]
          },
          code: this.$store.getters.currentCustomer.code,
          date_birth: null,
          genre: {
            selected: null,
            options: [
              {
                text: 'Masculino',
                value: 'M',
                disabled: this.$store.getters.currentCustomer.type == '1' ? false : true
              },
              {
                text: 'Feminino',
                value: 'F',
                disabled: this.$store.getters.currentCustomer.type == '1' ? false : true
              },
              {
                text: 'Indefinido',
                value: 'I',
                disabled: this.$store.getters.currentCustomer.type == '1' ? true : false
              }
            ]
          },
          registration_document: null,
          responsible_document: null
        },
        step_2: {
          fileDocumentFront: null,
          fileDocumentVerse: null,
          fileDocumentSelfie: null
        },
        step_3: {
          fileDocumentAddress: null,
          zip_code: null,
          address: null,
          number: null,
          neighborhood: null,
          ecosystem_state_id: null,
          ecosystem_city_id: null
        }
      },
      optionsState: [],
      optionsCities: []
    };
  },
  computed: {
    ...mapGetters(['currentStatus', 'currentCustomer'])
  },
  validations: {
    form: {
      step_1: {
        name: {
          required
        },
        type: {
          selected: {
            required
          }
        },
        code: {
          required
        },
        date_birth: {
          required
        },
        genre: {
          selected: {
            required
          }
        },
        registration_document: {
          required: requiredIf(function () {
            return this.form.step_1.type.selected == 1;
          })
        },
        responsible_document: {
          required: requiredIf(function () {
            return this.form.step_1.type.selected == 1;
          })
        }
      },
      step_2: {
        fileDocumentFront: {
          required,
          validateTypeDocument,
          validateFileSize
        },
        fileDocumentVerse: {
          required,
          validateTypeDocument,
          validateFileSize
        },
        fileDocumentSelfie: {
          required,
          validateTypeDocument,
          validateFileSize
        }
      },
      step_3: {
        fileDocumentAddress: {
          required,
          validateTypeDocument,
          validateFileSize
        },
        zip_code: {
          regexZipCode(zip_code) {
            return /^[0-9]{5}-[0-9]{3}$/.test(zip_code);
          }
        },
        address: {
          required
        },
        number: {
          required
        },
        neighborhood: {
          required
        },
        ecosystem_state_id: {
          required
        },
        ecosystem_city_id: {
          required
        }
      }
    }
  },
  watch: {
    'form.step_3.ecosystem_state_id': async function (state) {
      this.optionsCities = await this.getCitiesByStateID(state);
    },
    '$store.getters.currentStatus': function () {
      this.VerifyPendingLoader();
    },
    'form.step_1.type.selected': function (type) {
      this.form.step_1.genre.options[0].disabled = type == 2;
      this.form.step_1.genre.options[1].disabled = type == 2;
      this.form.step_1.genre.options[2].disabled = type != 2;

      if (type == 2) {
        this.form.step_1.genre.selected = 'I';
      } else {
        this.form.step_1.genre.selected = null;
      }

      // this.form.step_1.code = '';
    },
    deep: true
  },
  async mounted() {
    this.form.step_1.name = this.currentCustomer.name;

    this.optionsState = await this.getStates();
    this.VerifyPendingLoader();
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil', route: 'profile' }, { title: 'Validação de conta' }]);
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: this.currentStatus.id == 4 ? 4 : 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    const vuevalidate = this.$v;
    wizard.on('change', function (wizardObj) {
      if (wizardObj.getStep() > wizardObj.getNewStep()) {
        return; // Skip if stepped back
      }
      // Validate form before change wizard step
      const validator = vuevalidate.form['step_' + wizardObj.getStep()]; // get validator for current step
      validator.$touch();
      if (validator.$anyError) {
        wizardObj.stop();
        return;
      }
    });
  },
  methods: {
    async onSubmit() {
      this.loader.show = true;
      this.loader.timing = true;
      this.loader.message = 'Aguarde... Enquanto estamos organizando seus documentos.';

      let form = this.$v.form;
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   this.isSubmitting = false;
      //   return;
      // }
      let zip_code = form.step_3.zip_code.$model;
      zip_code = zip_code.replace('-', null);

      const formData = new FormData();
      formData.append('name', form.step_1.name.$model);
      formData.append('type', form.step_1.type.selected.$model);
      formData.append('code', form.step_1.code.$model);
      formData.append('date_birth', form.step_1.date_birth.$model);
      formData.append('genre', form.step_1.genre.selected.$model);
      formData.append('registration_document', form.step_1.registration_document.$model);
      formData.append('reponsible_document', form.step_1.reponsible_document.$model);

      formData.append('fileDocumentFront', form.step_2.fileDocumentFront.$model);
      formData.append('fileDocumentVerse', form.step_2.fileDocumentVerse.$model);
      formData.append('fileDocumentSelfie', form.step_2.fileDocumentSelfie.$model);

      formData.append('zip_code', zip_code);
      formData.append('address', form.step_3.address.$model);
      formData.append('number', form.step_3.number.$model);
      formData.append('neighborhood', form.step_3.neighborhood.$model);
      formData.append('ecosystem_city_id', form.step_3.city.$model);
      formData.append('fileDocumentAddress', form.step_3.fileDocumentAddress.$model);

      const submitButton = this.$refs['btn_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('SEND_DOCUMENTS', formData)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });

          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        })
        .catch(() => {
          this.loader.show = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    validate(step, name) {
      const { $dirty, $error } = this.$v.form[step][name];
      return $dirty ? !$error : null;
    },
    validateRadio(step, name) {
      const { $dirty, $error } = this.$v.form[step][name]['selected'];
      // console.log(this.$v.form[step][name]['selected']);
      return $dirty ? !$error : null;
    },
    validationMsg: validationMessage(formMessages),
    async searchCep() {
      let formdata = this.$v.form.step_3;

      if (/^[0-9]{5}-[0-9]{3}$/.test(formdata.zip_code.$model)) {
        this.gettingCep.show = true;
        this.gettingCep.message = 'Buscando...';
        this.gettingCep.status = 'warning';

        const cep = await this.getAddressByCep(formdata.zip_code.$model)
          .then(async (response) => {
            this.form.step_3 = Object.assign(this.form.step_3, response);

            this.$refs['number'].focus();
            this.gettingCep.message = 'Cep encontrado com sucesso!';
            this.gettingCep.status = 'success';
          })
          .catch((err) => {
            this.gettingCep.message = 'Ops... Cep incorreto ou não encontrado!';
            this.gettingCep.status = 'danger';
          });
      }
      // if (/^[0-9]{5}-[0-9]{3}$/.test(formdata.zip_code.$model)) {
      //   this.gettingCep.show = true;
      //   this.gettingCep.message = 'Buscando...';
      //   this.gettingCep.status = 'warning';

      //   cep(this.form.step_3.zip_code, { timeout: 5000, providers: ['brasilapi'] })
      //     .then(async (response) => {
      //       const state = Object.values(this.optionsState).filter((state) => state.state_uf == response.state);
      //       formdata.ecosystem_state_id.$model = state[0].state_id;

      //       this.optionsCities = await this.getCitiesByStateID(state[0].state_id);
      //       const city = Object.values(this.optionsCities).filter((city) => city.name == response.city);
      //       formdata.ecosystem_city_id.$model = city[0].id;

      //       formdata.address.$model = response.street;
      //       formdata.neighborhood.$model = response.neighborhood;
      //       this.$refs['number'].focus();

      //       this.gettingCep.message = 'Cep encontrado com sucesso!';
      //       this.gettingCep.status = 'success';
      //     })
      //     .catch(() => {
      //       this.gettingCep.message = 'Ops... Cep incorreto ou não encontrado!';
      //       this.gettingCep.status = 'danger';
      //     });
      // }
    },
    handleScroll(e) {
      if (e.target.scrollTop > e.target.clientHeight) {
        this.terms = true;
      }
    },
    VerifyPendingLoader() {
      if (this.$store.getters.currentStatus.id == 4 || this.$store.getters.currentStatus.id == 1) {
        this.loader.show = true;
        this.loader.timing = true;
        this.loader.message = this.currentStatus.description_client;
      }
    }
  }
};
</script>
